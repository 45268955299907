const frameworks = "Frameworks";
const tasks = "Tasks";
const machines = "Machines";

export const FilterGroupNames = {
    frameworks,
    tasks,
    machines
};

