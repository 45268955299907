export const image_classification = "image_classification";
export const image_enhancement = "image_enhancement";
export const object_detection = "image_object_detection";
export const semantic_segmentation = "image_semantic_segmentation";
export const instance_segmentation = "image_instance_segmentation";
export const imageTo3D = "image_to_3D";
export const styleTransfer = "style_transfer";
export const maskGeneration = "mask_generation";
export const textToText = "text_to_text";
export const textToCode = "text_to_code";
export const audioToText = "audio_to_text";
export const textToAudio = "text_to_audio";
export const textConversation = "text_conversation";
export const visualQuestionAnswering = "visual_question_answering";
export const textGuidedImageToImage = "text_guided_image_to_image";
export const documentQuestionAnswering = "document_question_answering";
export const textToImage = "text_to_image";
export const textToVideo = "text_to_video";
export const textTo3D = "text_to_3D";
export const imageToText = "image_to_text";
export const textClassification = "text_classification";
export const audioToAudio = "audio_to_audio";
export const audioClassification = "audio_classification";
export const videoClassification = "video_classification";
export const tableEditing = "table_editing";
export const pending = "pending";
