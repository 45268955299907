export const TestBoundingBox = {
  "bounding_box": {
    "index": 15,
    "label": "cat",
    "xmax": 352.969,
    "xmin": 85.00500012,
    "ymax": 245.986,
    "ymin": 30.984,
    color: {
      className: "test-class-1",
      font: "#ffffff",
      background: "#123456"
    }
  },
  "id": "61a67f3e065391000171cf2a",
  "probability": 0.35077477,
  "type": "BOUNDINGBOX"
};

const TestFeatures = [
  {
    id: "62606f6aec8a0b0001cbef7f",
    probability: 0.9899497,
    type: "BOUNDINGBOX",
    bounding_box: {
      "index": 3,
      "label": "car1",
      "xmax": 0.7149137,
      "xmin": 0.6304271,
      "ymax": 0.5578812,
      "ymin": 0.43660367
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef80",
    "probability": 0.98971725,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car2",
      "xmax": 0.48686394,
      "xmin": 0.4047421,
      "ymax": 0.56226575,
      "ymin": 0.4319585
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef81",
    "probability": 0.96391344,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car3",
      "xmax": 0.47190666,
      "xmin": 0.35543925,
      "ymax": 0.7887854,
      "ymin": 0.5835101
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef82",
    "probability": 0.96019036,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car4",
      "xmax": 0.36904687,
      "xmin": 0.29868793,
      "ymax": 0.46466157,
      "ymin": 0.3675814
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef83",
    "probability": 0.9543532,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car5",
      "xmax": 0.0919764,
      "xmin": 0.003532268,
      "ymax": 0.9076059,
      "ymin": 0.7104186
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef84",
    "probability": 0.9503462,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car6",
      "xmax": 0.3420029,
      "xmin": 0.28587827,
      "ymax": 0.2850022,
      "ymin": 0.20647343
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef85",
    "probability": 0.94873506,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car7",
      "xmax": 0.41170514,
      "xmin": 0.3687626,
      "ymax": 0.26212952,
      "ymin": 0.18145029
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef86",
    "probability": 0.94511676,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car8",
      "xmax": 0.20447105,
      "xmin": 0.101608396,
      "ymax": 0.5969248,
      "ymin": 0.45126498
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef87",
    "probability": 0.93983,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car9",
      "xmax": 0.5411325,
      "xmin": 0.4854608,
      "ymax": 0.31020522,
      "ymin": 0.2342757
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef88",
    "probability": 0.93379736,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car00",
      "xmax": 0.6657317,
      "xmin": 0.60273165,
      "ymax": 0.4745894,
      "ymin": 0.3931511
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef89",
    "probability": 0.9216288,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car11",
      "xmax": 0.5465958,
      "xmin": 0.49133825,
      "ymax": 0.38870305,
      "ymin": 0.3100301
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8a",
    "probability": 0.9189916,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car22",
      "xmax": 0.6516052,
      "xmin": 0.5144976,
      "ymax": 0.8866693,
      "ymin": 0.64972377
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8b",
    "probability": 0.90702856,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.39412922,
      "xmin": 0.34315342,
      "ymax": 0.33652747,
      "ymin": 0.26556188
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8c",
    "probability": 0.8911355,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.3804456,
      "xmin": 0.32257503,
      "ymax": 0.39229578,
      "ymin": 0.31128293
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8d",
    "probability": 0.8707419,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.8939638,
      "xmin": 0.73190224,
      "ymax": 0.9867407,
      "ymin": 0.7152333
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8e",
    "probability": 0.79349816,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.65846676,
      "xmin": 0.589225,
      "ymax": 0.4269698,
      "ymin": 0.33161667
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef8f",
    "probability": 0.74665195,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.49437186,
      "xmin": 0.4499916,
      "ymax": 0.24169683,
      "ymin": 0.17766872
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef90",
    "probability": 0.7159157,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.14332612,
      "xmin": 0.018896159,
      "ymax": 0.7181281,
      "ymin": 0.54153347
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef91",
    "probability": 0.65877557,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 6,
      "label": "bus",
      "xmax": 1.05539617,
      "xmin": 0.7681469,
      "ymax": 0.6746725,
      "ymin": 0.37251905
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef92",
    "probability": 0.62878084,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.29076588,
      "xmin": 0.15140605,
      "ymax": 0.8630197,
      "ymin": 0.6244234
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef93",
    "probability": 0.6277414,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.3175339,
      "xmin": 0.271735,
      "ymax": 0.34198454,
      "ymin": 0.265426
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef94",
    "probability": 0.54443526,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 6,
      "label": "bus",
      "xmax": 0.8119842,
      "xmin": 0.6651434,
      "ymax": 0.4222965,
      "ymin": 0.20542333
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef95",
    "probability": 0.5404018,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 6,
      "label": "bus",
      "xmax": 0.56566393,
      "xmin": 0.51185894,
      "ymax": 0.21143836,
      "ymin": 0.09157767
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef96",
    "probability": 0.52928567,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 8,
      "label": "truck",
      "xmax": 0.6456134,
      "xmin": 0.5734151,
      "ymax": 0.38106722,
      "ymin": 0.24902242
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef97",
    "probability": 0.52416265,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.80323666,
      "xmin": 0.7437766,
      "ymax": 0.2693483,
      "ymin": 0.20776872
    }
  },
  {
    "id": "62606f6aec8a0b0001cbef98",
    "probability": 0.0050269955,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 3,
      "label": "car",
      "xmax": 0.46148986,
      "xmin": 0.40860546,
      "ymax": 0.39400023,
      "ymin": 0.3047951
    }
  }
];
const TestFeatures2 = [
  {
    "id": "628bdc55e53c2400012eee77",
    "probability": 0.99758387,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 2,
      "label": "bicycle",
      "xmax": 0.7165876,
      "xmin": 0.31575793,
      "ymax": 1,
      "ymin": 0.6313702
    }
  }, {
    "id": "628bdc55e53c2400012eee78",
    "probability": 0.99527717,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 62,
      "label": "chair",
      "xmax": 0.8277925,
      "xmin": 0.6971804,
      "ymax": 0.8583325,
      "ymin": 0.6134716
    }
  }, {
    "id": "628bdc55e53c2400012eee79",
    "probability": 0.9662732,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 62, "label": "chair", "xmax": 1, "xmin": 0.9316256, "ymax": 0.7616314, "ymin": 0.5683819}
  }, {
    "id": "628bdc55e53c2400012eee7a",
    "probability": 0.96593505,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 64,
      "label": "potted plant",
      "xmax": 0.1477233,
      "xmin": 0.0048494297,
      "ymax": 0.9494788,
      "ymin": 0.61504614
    }
  }, {
    "id": "628bdc55e53c2400012eee7b",
    "probability": 0.96020025,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 64,
      "label": "potted plant",
      "xmax": 0.3133616,
      "xmin": 0.26673105,
      "ymax": 0.3476335,
      "ymin": 0.21612178
    }
  }, {
    "id": "628bdc55e53c2400012eee7c",
    "probability": 0.9595949,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 62,
      "label": "chair",
      "xmax": 0.9266352,
      "xmin": 0.8242973,
      "ymax": 0.74595004,
      "ymin": 0.5583175
    }
  }, {
    "id": "628bdc55e53c2400012eee7d",
    "probability": 0.94966215,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 64,
      "label": "potted plant",
      "xmax": 0.45099917,
      "xmin": 0.35547662,
      "ymax": 0.38596445,
      "ymin": 0.1904124
    }
  }, {
    "id": "628bdc55e53c2400012eee7e",
    "probability": 0.83777773,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 64,
      "label": "potted plant",
      "xmax": 0.29786748,
      "xmin": 0.13575846,
      "ymax": 0.92544425,
      "ymin": 0.5983867
    }
  }, {
    "id": "628bdc55e53c2400012eee7f",
    "probability": 0.6652413,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 1,
      "label": "person",
      "xmax": 0.58129424,
      "xmin": 0.51869243,
      "ymax": 0.639403,
      "ymin": 0.45970267
    }
  }, {
    "id": "628bdc55e53c2400012eee80",
    "probability": 0.6559491,
    "type": "BOUNDINGBOX",
    "bounding_box": {
      "index": 1,
      "label": "person",
      "xmax": 0.6579427,
      "xmin": 0.53580487,
      "ymax": 0.6751982,
      "ymin": 0.4715923
    }
  }, {
    "id": "628bdc55e53c2400012eee83",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee84",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee85",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee86",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee87",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee88",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee89",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee8a",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee81",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }, {
    "id": "628bdc55e53c2400012eee82",
    "probability": 0,
    "type": "BOUNDINGBOX",
    "bounding_box": {"index": 1, "label": "person", "xmax": 0, "xmin": 0, "ymax": 0, "ymin": 0}
  }];

const MakeObjectDetectionResult = (input, features) => ({
  id: "f5bbc476-1b25-497e-9e64-b2375b5f57c5",
  inputs: [
    input
  ],
  completed_at: "2022-04-20T20:39:06.359287Z",
  results: {
    "duration": "492.161067ms",
    "duration_for_inference": "368.568412ms",
    responses: [
      {
        features: features
      }
    ],
    "trace_id": {}
  },
  model: {
    framework: {
      architectures: [
        {
          name: 'amd64'
        }
      ],
      name: 'PyTorch'
    },
    id: 27,
    name: 'TorchVision+ResNet18',
    output: {
      type: "image_object_detection"
    }
  },
})

export const TestObjectDetectionResult = MakeObjectDetectionResult("http://www.experian.com/blogs/news/wp-content/uploads/2012/06/cars.png", TestFeatures);
export const TestObjectDetectionResult2 = MakeObjectDetectionResult("https://s3.amazonaws.com/uploads.staging.mlmodelscope.org/obj-2.jpg", TestFeatures2);

export const TestObjectDetectionResultNoBoundingBoxes = MakeObjectDetectionResult("https://s3.amazonaws.com/uploads.staging.mlmodelscope.org/obj-3.jpg", [])

