import { DefaultTextToVideo } from "../../../../../../helpers/DefaultModels";

export const TestTextToVideoGeneratedToken = {
    id: "sampleTestTextToVideoOutputIdHere"
};


export const TestTextToVideoOutput = {
    id: "sampletesttexttovideooutputidhere",
    inputs: [
        { src: 'a chill song with influences from lofi, chillstep and downtempo' },
    ],
    completed_at: "2023-06-03T18:17:14.513854Z",
    results: {
        'duration': "9.216154124s",
        'duration_for_inference': "9.193807904s",
        'responses': [
            {
                'features': [
                    {
                        title: "text-to-video-output.mp4",
                        src: "https://xlab1.netlify.app/samples/video/flower.mp4",
                        type: 'VIDEO'
                    }
                ],
                'id': "sampletesttexttovideooutputresponseidhere"
            }
        ]
    },
    model: DefaultTextToVideo,
};