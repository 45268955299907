import { DefaultTextToImage } from "../../../../../../helpers/DefaultModels";

export const TestTextToImageGeneratedToken = {
    id: "sampleTestTextToImageOutputIdHere"
};


export const TestTextToImageOutput = {
    id: "sampletesttexttovideooutputidhere",
    inputs: [
        { src: 'a chill song with influences from lofi, chillstep and downtempo' },
    ],
    completed_at: "2023-06-03T18:17:14.513854Z",
    results: {
        'duration': "9.216154124s",
        'duration_for_inference': "9.193807904s",
        'responses': [
            {
                'features': [
                    {
                        title: "text-to-image-output.png",
                        src: "https://s3.amazonaws.com/uploads.staging.mlmodelscope.org/birdy.png",
                        type: 'IMAGE'
                    }
                ],
                'id': "sampletesttexttovideooutputresponseidhere"
            }
        ]
    },
    model: DefaultTextToImage,
};